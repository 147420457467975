import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
    hideHeader,
    hideBottomNav,
    hideSideDrawer,
} from "../../../state/actions";

import { Col, Container, Row } from "reactstrap";
import HomeCard from "../../molecules/HomeCard/HomeCard";

import "./home.scss";

const Home = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(hideBottomNav());
        dispatch(hideSideDrawer());

        document.body.classList.add("page-home");

        return () => {
            document.body.classList.remove("page-home");
        };
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    <div className="home">
                        <HomeCard />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
