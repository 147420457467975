import React from "react";

import Layout from "../layout";
import SEO from "../components/seo";
import Home from "../components/organisms/Home/Home";

const Index = () => {
    return (
        <Layout>
            <SEO title="Strona główna" />
            <Home />
        </Layout>
    );
};

export default Index;
